<template lang="pug">
include ../pug/svg
div#activitylist(if="course")
	div#ctrl
		div
			Search(v-model:search="search")
			Filter(id="attemptFilter" label="Attempt" title="Attempts" :options="attemptFilters" v-model:selected="attemptFilter")
	div.wrapper
		div.c1.listbg
			ul.list
				li.person(v-for="(attempt, index) in filteredAttempts" :attempt="attempt" :index="index" @mouseenter="previewIndex=index" :class="{active:previewIndex===index}")
					picture.user(v-if="attempt.user.profileUrl" :class="{online:attempt.user.online}")
						img(:src="attempt.user.profileUrl" width="40" height="40" :alt="attempt.user.fullName")
					span.user(v-else :class="{online:attempt.user.online}") {{attempt.user.initials}}
					
					div.hgroup
						h2 {{attempt.user.fullName}}
						h3 {{terminology('user',attempt.courseRole.slug,'singular')}}
						
					div.butmore(v-if="attempt.tasks.examMode && attempt.tasks.total")
						a(v-if="attempt.completed && attempt.tasks.pending" role="button" @click="toggleModal('pending')") Mark Tasks
						a(v-else role="button" @click="toggleModal(true)") All Activity
						button.but(type="button" aria-haspopup="true" aria-controls="" @mouseenter="toggleTint(true),setMenu($event)" @mouseleave="toggleTint(false)") More
							+svg(svg-filename="iconMore" aria-hidden="true" alt="More icon")
						nav.menu(@mouseenter="toggleTint(true)" @mouseleave="toggleTint(false)")
							div.bar
								+svg(svg-filename="iconMore" aria-hidden="true")
								a.but(role="button") Close
									+svg(svg-filename="iconClose")
							ul
								li
									a(role="button" @click="toggleModal('all')") View tasks
								li(v-if="attempt.tasks.correct")
									a(role="button" @click="toggleModal('correct')") View correct tasks
								li(v-if="attempt.tasks.incorrect")
									a(role="button" @click="toggleModal('incorrect')") View incorrect tasks
								li(v-if="attempt.tasks.pending")
									a(role="button" @click="toggleModal('pending')") View unmarked tasks
									
					button.but.sec(v-else type="button" @click="toggleModal(true)") All Activity
					
					table.summary
						//-tr(v-if="attempt.attempt>1")
							th Retake
							td(v-if="!attempt.started") Unattempted
							td(v-else) {{number2words(attempt.attempt-2)}} retake
						tr
							th Progress
							td(v-if="!attempt.started") Unattempted
							td(v-else-if="attempt.completed") Completed
							td(v-else) {{completedLessons(attempt)}} of {{totalLessons(attempt)}}
						tr(v-if="attempt.tasks.examMode")
							th {{terminology('course','point','plural')}}
							td(v-if="attempt.completed") {{attempt.tasks.examUserScore}}/{{attempt.tasks.examMaxScore}}
							td(v-else)
								+svg(svg-filename='iconMore')
						tr(v-else)
							th Tasks
							td(v-if="attempt.completed") {{attempt.tasks.correct}}/{{attempt.tasks.total}} Correct
							td(v-else)
								+svg(svg-filename='iconMore')
						tr
							th Result
							td(v-if="attempt.tasks.result===true")
								+svg(svg-filename='iconHappy') 
								span(v-if="attempt.tasks.examMode") Passed
							td(v-else-if="attempt.tasks.result===false") 
								+svg(svg-filename='iconSad')
								span(v-if="attempt.tasks.examMode") Failed
							td(v-else)
								+svg(svg-filename='iconMore')
					a.arrow View Attempt
						+svg(svg-filename='iconArrow')
		div.c2.sticky(v-if="previewAttempt")
			div.preview
				div.head
					h4 {{previewAttempt.user.fullName}}
					h6(v-if="previewAttempt.user.online") Online
					h6(v-else) Offline
				div.body
					picture.user(v-if="previewAttempt.user.profileUrl" :class="{online:previewAttempt.user.online}")
						img(:src="previewAttempt.user.profileUrl" width="40" height="40" :alt="previewAttempt.user.fullName")
					span.user(v-else :class="{online:previewAttempt.user.online}") {{previewAttempt.user.initials}}
					table.column
						tr
							th Attempt
							td(v-if="!previewAttempt.started") Unattempted
							td(v-else) {{number2words(previewAttempt.attempt-1)}} attempt
						tr
							th {{terminology('course','lesson','singular')}} Progress
							td {{completedLessons(previewAttempt)}} of {{totalLessons(previewAttempt)}} Completed
						tr
							th Completion Time
							td(v-if="!previewAttempt.started") Unattempted
							td(v-else) {{courseCompletionTime(previewAttempt)}}
						tr(v-if="previewAttempt.tasks.examMode") 
							th Exam Pass Threshold
							td
								span(v-if="previewAttempt.tasks.examPassScore==1") {{previewAttempt.tasks.examPassScore}} out of {{previewAttempt.tasks.examMaxScore}} {{terminology('course','point','singular',true)}}
								span(v-else) {{previewAttempt.tasks.examPassScore}} out of {{previewAttempt.tasks.examMaxScore}} {{terminology('course','point','plural',true)}} are required to pass
					table.numeric
						tr
							th Tasks
							th Correct
							th(v-if="previewAttempt.tasks.examMode && previewAttempt.completed") {{terminology('course','point','plural')}}
							th(v-else) Incorrect
							th(v-if="previewAttempt.tasks.pending") Unmarked
							th Result
						tr
							td {{previewAttempt.tasks.total}}
							td {{previewAttempt.tasks.correct}}
							td(v-if="previewAttempt.tasks.examMode && previewAttempt.completed")
								span {{previewAttempt.tasks.examUserScore}}
							td(v-else) {{previewAttempt.tasks.incorrect}}
							td(v-if="previewAttempt.tasks.pending") {{previewAttempt.tasks.pending}}
							td(v-if="previewAttempt.tasks.result===true || (previewAttempt.tasks.examMode && previewAttempt.tasks.examUserScore >= previewAttempt.tasks.examPassScore)")
								+svg(svg-filename='iconHappy')
							td(v-else-if="previewAttempt.tasks.result===false")
								+svg(svg-filename='iconSad')
							td(v-else)
								+svg(svg-filename='iconMore')
	div.modal.center(:class="{active:showModal}")
		template(v-if="previewAttempt")
			div.bar
				+svg(svg-filename="iconMore" aria-hidden="true")
				span Activity / {{previewAttempt.user.fullName}}
				button.but(type="button" @click="toggleModal(false)") Close
					+svg(svg-filename="iconClose")
			div.body
				p
					Filter(id="activityFilter" label="Task Filter" title="Attempts" :options="activityFilters" v-model:selected="activityFilter" :setTint="false")
				dl.activity
					template(v-for="activity in filteredActivities")
						dt(:class="activity.activityType")
							template(v-if="activity.activityType==='task'")
								em(v-if="activity.activityData.taskResult===true")
									+svg(svg-filename='iconTick')
								em(v-else-if="activity.activityData.taskResult===false")
									+svg(svg-filename='iconClose')
								em(v-else)
									+svg(svg-filename="iconMore" aria-hidden="true")
							em(v-else)
								+svg(svg-filename='iconMortarboard')
							span {{formatDate(activity.activityDatetime)}} - {{translateLogTitle(activity.activityData.logTitle)}}
						dd(v-if="activity.activityType === 'course'")
							div {{activity.activityData.courseName}}
						dd(v-if="activity.activityType === 'lesson'")
							div {{activity.activityData.lessonName}}
						dd(v-if="activity.activityType === 'task'")
							div.question {{activity.activityData.taskQuestion}}
							template(v-if="activity.activityData.taskResult===true")
								span(v-if="activity.activitySubtype === 'freeform'") User Answer - Awarded ({{activity.activityData.userPoints}}/{{activity.activityData.examPoints}} {{terminologyNumeric(activity.activityData.examPoints,'course','point','plural',true)}})
								span(v-else) User Answer - Correct ({{activity.activityData.examPoints}} {{terminologyNumeric(activity.activityData.examPoints,'course','point','plural',true)}})
							span(v-else-if="activity.activityData.taskResult===false") User Answer - Incorrect (0 {{terminology('course','point','plural',true)}})
							span(v-else) User Answer - Pending Marking (Award up to {{activity.activityData.examPoints}} {{terminologyNumeric(activity.activityData.examPoints, 'course','point','plural',true)}})
							//-pre {{activity.activityData}}

							ul.answers(v-if="activity.activitySubtype==='radio' || activity.activitySubtype==='checkbox'")
								li(v-for="(answer, i) in activity.activityData.taskAnswers" :class="{correct:answer.correct,selected:activity.activityData.userAnswers.includes(i)}") {{answer.option}}
									em(v-if="answer.correct")
										+svg(svg-filename='iconHappy')
									em(v-else)
										+svg(svg-filename='iconSad')

							template(v-else-if="activity.activitySubtype==='freeform' && activity.activityData.taskResult===null")
								div.answer(v-html="activity.activityData.userAnswer")
								Form.mark(:validation-schema="markSchema" @submit="markTask")
									Field(name="activityId" type="hidden" :value="activity.id")
									button.but.pri(type="submit") Award Points
									Field(name="userPoints" type="text" maxlength="4" :value="activity.activityData.examPoints" @keydown="keydown")
									ErrorMessage(name="userPoints")
				div.foot
					button.but.sec(type="button" @click="toggleModal(false)") Close
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import Search from '../components/Search';
import Filter from '../components/Filter';

export default {
	name: 'ManageLearningCourseActivity',
	props: ['course'],
	emits: ['storeUpdated'],
	components: {
		Search,
		Filter,
		Form,
		Field,
		ErrorMessage,
	},
	data() {
//		const req = 'Required';
//		const inv = 'Invalid';
		const markSchema = Yup.object().shape({
			userPoints: Yup.string().nullable(),
		});
		return {
			markSchema,			
			search: null,
			previewIndex: 0,
			showModal: false,
			attemptFilter: null,
			attemptFilters: [
				{
					value: 'unattempted',
					option: 'Unattempted',
				},
				{
					value: 'finished',
					option: 'Finished',
				},
				{
					value: 'unfinished',
					option: 'Unfinished',
				},
				{
					value: 'passed',
					option: 'Passed',
				},
				{
					value: 'failed',
					option: 'Failed',
				},
			],
			activityFilter: null,
			activityFilters: [
				{
					value: 'all',
					option: 'All tasks',
				},
				{
					value: 'correct',
					option: 'Correct tasks',
				},
				{
					value: 'incorrect',
					option: 'Incorrect tasks',
				},
				{
					value: 'pending',
					option: 'Unmarked tasks',
				},
			],
			ordinals : 'First Second Third Fourth Fith Sixth Seventh Eight Ninth Tenth Eleventh Twelfth Thirteenth Fourteenth Fifteenth Sixteenth Seventeenth Eighteenth Nineteenth'.split(' '),
			nums : 'zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen'.split(' '),
			tens : 'twenty thirty forty fifty sixty seventy eighty ninety'.split(' '),
		};
	},
	mounted() {
		this.refreshUsers();
	},
	watch: {
		course: {
			handler(course) {
				this.$store.dispatch('gui/setHeader', {
					title: course.name,
					backRoute: {
						text: this.terminology('course','course','plural'),
						name: 'ManageLearning',
					},			
				});		
			},
			deep: false,
		},
	},
	methods: {
		keydown(event) {
			const allowedKeys = ['Backspace','Shift','Tab','ArrowLeft','ArrowRight','ArrowUp','ArrowDown','0','1','2','3','4','5','6','7','8','9'];
			
			if (allowedKeys.indexOf(event.key) < 0) {
				// restricted key
				event.preventDefault();
			}
		},
		markTask(values, actions) {
			const activity = this.filteredActivities.find(a => a.id == values.activityId);
			const maxPoints = activity.activityData.examPoints;
			let userPoints = values.userPoints;
			
			if (userPoints > maxPoints) {
				actions.setFieldError('userPoints', 'Maximum award is '+maxPoints+' points');
				return;
				
			} else if (userPoints < 0) {
				actions.setFieldError('userPoints', 'Minimum award is 0 points');
				return;
			}
			
			// update activity data
			this.$store.dispatch('courses/setUserTaskPoints', {
				activity: activity,
				taskResult: (userPoints > 0),
				userPoints,
			});
		},
		setMenu(event) {
			if (event.target.nodeName === 'BUTTON' && event.relatedTarget.nodeName !== 'NAV') {
				const menu = event.target.nextSibling;
				menu.classList.remove('reverse');				
				const rect = menu.getBoundingClientRect();
				
				if (rect.bottom > window.innerHeight) {
					menu.classList.add('reverse');
				}
			}
		},
		toggleTint(bool) {
			this.$store.dispatch('gui/setTintState', bool);
		},
		toggleModal(bool) {
			if (bool !== true && bool !== false) {
				this.activityFilter = this.activityFilters.find(f => f.value === bool);
				bool = true;
			}
			
			this.showModal = bool;
			this.$store.dispatch('gui/setTintState', bool);
		},
		terminologyNumeric(number, groupKey, termKey, lowercase) {
			const varKey = (number == 1) ? 'singular' : 'plural';
			return this.terminology(groupKey, termKey, varKey, lowercase);
			
		},
		terminology(groupKey, termKey, varKey, lowercase) {
			const translation = this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
			return (lowercase) ? translation.toLowerCase() : translation;
		},
		translateLogTitle(logTitle) {
			for (let key in this.$store.state.gui.termMap.course.vars) {
				const singular = this.terminology('course', key, 'singular');
				const re = new RegExp(key, 'gi');
				
				logTitle = logTitle.replace(re, singular); 
			}
			
			return logTitle;
		},
		async refreshUsers() {
			// load users from db
			await this.$store.dispatch('users/dbUsers');
		},
		formatDate(isoDate) {
			const date = new Date(isoDate);
			return date.toLocaleString('en-GB', {
				timeZone:'UTC',
				month:'short',
				year: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				hour12: true,
			});
		},
		number2words(n) {
//			if (n < 20) return this.ordinals[n];
			if (n < 20) return this.ordinals[n];
			
			var digit = n%10;
			if (n < 100) return this.tens[~~(n/10)-2] + (digit? '-' + this.nums[digit]: '');
			if (n < 1000) return this.nums[~~(n/100)] +' hundred' + (n%100 == 0? '': ' and ' + this.number2words(n%100));
			return this.number2words(~~(n/1000)) + ' thousand' + (n%1000 != 0? ' ' + this.number2words(n%1000): '');
		},
		totalLessons() {
			return this.course.courseLessons.length;
		},
		completedLessons(attempt) {
			const completedLessons = attempt.activities.filter(a => a.activityType === 'lesson' && a.activitySubtype === 'completed');
			return completedLessons.length;
		},
		courseCompletionTime(attempt) {
			if (attempt.completed === false) {
				return 'In Progress';
				
			} else {
				const courseCompletion = attempt.activities.find(a => a.activityType === 'course' && a.activitySubtype === 'completed');
				const totalSeconds = courseCompletion.activityData.completionTime;
				
				const days = Math.floor(totalSeconds / (3600 * 24));
				const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
				const minutes = Math.floor((totalSeconds % 3600) / 60);
				const seconds = Math.floor(totalSeconds % 60);
				
				const daysStr = this.pluraliseTime(days, 'day');
				const hoursStr = this.pluraliseTime(hours, 'hour');
				const minutesStr = this.pluraliseTime(minutes, 'minute');
				const secondsStr = this.pluraliseTime(seconds, 'second');
				const timeStr = daysStr + hoursStr + minutesStr + secondsStr;
				
				return timeStr.replace(/,\s*$/, '');
			}
		},
		pluraliseTime(num, singular) {
			return num > 0 ? num + (num === 1 ? ' '+singular+', ' : ' '+singular+'s, ') : '';
		},
	},
	computed: {
		attempts() {
			// group activity by user attempts
			return (!this.course) ? [] : this.$store.getters['courses/getCourseAttempts'](this.course.id);
		},
		filteredAttempts() {
			// attempt filter
			let f1 = (this.attemptFilter) ? this.attempts.filter(a => {
				let bool = false;
				if (this.attemptFilter.value === 'unattempted') {
					bool = (a.attempt === null);
				} else if (this.attemptFilter.value === 'finished') {
					bool = (a.completed !== false);
				} else if (this.attemptFilter.value === 'unfinished') {
					bool = (a.completed === false && a.attempt !== null);
				} else if (this.attemptFilter.value === 'passed') {
					bool = (a.tasks.result === true);
				} else if (this.attemptFilter.value === 'failed') {
					bool = (a.tasks.result === false);
				}
				
				return bool;
				
			}) : this.attempts.filter(a => a.attempt !== null);
			
			// serch term filter
			return (this.search) ? f1.filter(a => a.user.fullName.toLowerCase().includes(this.search.toLowerCase())) : f1;
		},
		previewAttempt() {
			return this.filteredAttempts[this.previewIndex] || null;
		},
		filteredActivities() {
			if (!this.previewAttempt) {
				return [];
			}
			
			return (this.activityFilter) ? this.previewAttempt.activities.filter(a => {
				// filter activity log
				const value = this.activityFilter.value;
				
				if (value === 'correct') {
					return (a.activityType === 'task' && a.activityData.taskResult === true);
					
				} else if (value === 'incorrect') {
					return (a.activityType === 'task' && a.activityData.taskResult === false);
					
				} else if (value === 'pending') {
					return (a.activityType === 'task' && a.activityData.taskResult === null);
					
				} else {
					return (a.activityType === 'task');
				}
				
			}) : this.previewAttempt.activities;
		},
	},
};
</script>

<style lang="scss">
#activitylist {
	width: 100%;
	form.mark {
		display: flex;
		flex-wrap: wrap;
		margin-top: 20px;
		input {
			order: -1;
			width: 112px;
			height: 30px;
			padding: 0 15px;
			border: 1px solid var(--pri-bg);
			color: var(--pan-col);
			background:  var(--pan-bg);
			border-top-left-radius: 15px;
			border-bottom-left-radius: 15px;
			transition: .3s easein-out;
		}
		button {
			min-width: 112px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			&:hover {
				+input {
					border-color: var(--pri-bg-hvr);
				}
			}
		}
		span {
			width: 100%;
		}
	}
	.wrapper {
		display: flex;
		.c1 {
			min-width: 290px;
		}
		.c2 {
			max-width: 420px;
			&.sticky {
				top: 140px;
			}
		}
		table.summary {
			width: 150px;
		}
		.preview {
			.body {
//				height: calc(100vh - 210px);
//				overflow: scroll;
			}
			table.numeric {
				svg {
					width: 24px;
					height: 24px;
				}
			}
		}
	}
	@media only screen and (min-width: 768px) {
		.wrapper {
			.c1 {
				flex-grow: 1;
				margin-right: 20px;
			}
			.c1,
			.c2 {
				width: calc(50% - 10px);
			}
		}
	}
}
</style>
